import React from 'react';
import useStyles from '../styles';

import Header from 'components/header';
import Footer from 'components/footer';

import Section1 from 'components/homepage/section1';
import Section2 from 'components/homepage/section2';
import Section3 from 'components/homepage/section3';

import Partners from 'components/partners';


const HomePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.App}>
      <Header lang={'en'} />

      <main className={classes.main}>
        <Section1 />
        <Section2 />
        <Section3 />

      </main>

      <Partners lang={'en'} />
      <Footer lang={'en'} />
    </div>
  );
};

export default HomePage;
