import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useStyles from '../styles';
import cx from 'classnames';

import Header from 'components/header';
import Footer from 'components/footer';

import Partners from 'components/partners';

import image1 from 'assets/city-photography/barcelos/barcelos-3.jpg';
import image2 from 'assets/city-photography/famalicao/famalicao-6.jpg';
import image3 from 'assets/city-photography/famalicao/famalicao-3.jpg';
import image4 from 'assets/city-photography/guimaraes/guimaraes-3.jpg';
import image5 from 'assets/city-photography/guimaraes/guimaraes-1.jpg';

const HomePage = () => {
  const classes = useStyles();

  const buttonOpenCall = cx(classes.buttonOpenCall);

  function icalGen() {
    var startDate = '20240416T100000'; // Adding "T100000" for 10 am
    var endDate = '20240417';
    var title = 'Open Call Square Festival.';
    var description = '';

    var fileData =
      'BEGIN:VCALENDAR\n' +
      'CALSCALE:GREGORIAN\n' +
      'METHOD:PUBLISH\n' +
      'PRODID:-//Test Cal//EN\n' +
      'VERSION:2.0\n' +
      'BEGIN:VEVENT\n' +
      'DTSTART:' +
      startDate;

    if (endDate !== null) {
      fileData += '\nDTEND;VALUE=DATE:' + endDate;
    }

    fileData +=
      '\n' +
      'SUMMARY:' +
      title +
      '\n' +
      'DESCRIPTION:' +
      description +
      '\n' +
      'BEGIN:VALARM\n' +
      'TRIGGER:PT0M\n' + // Alarm on the same day of the event
      'DESCRIPTION:Reminder for the event\n' +
      'ACTION:DISPLAY\n' +
      'END:VALARM\n' +
      'END:VEVENT\n' +
      'END:VCALENDAR';

    var data = new File([fileData], { type: 'text/plain' });

    var url = window.URL.createObjectURL(data);

    var a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = title + '.ics';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const [expanded, setExpanded] = useState(false); // State to toggle between states

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.App}>
      <Header lang={'pt'} />

      <main className={classes.main}>
        <section className={`${classes.card} ${classes.bgGreen}`}>
          <div className={classes.ratio}>
            <p className={classes.cardText}>
              SQUARE é um encontro e uma celebração do talento que vive e trabalha nas margens. Tendo como eixo a
              conexão entre os continentes banhados pelo Atlântico (África, América e Europa), o SQUARE celebra a
              diversidade ao criar um espaço de encontro para talento emergente e novas tendências da música oriunda
              desses territórios. A ter lugar entre os dias 29 de janeiro e 1 de fevereiro de 2025, nas cidades de
              Braga, Barcelos, Famalicão e Guimarães.
              <br /> <br />
              Informações sobre bilhetes serão anunciadas em setembro de 2024.
            </p>
            <div className={classes.seeMoreWrapper}>
              <div className={`${classes.seeMoreHidden} ${expanded ? classes.seeMoreVisible : ''}`}>
                <p className={classes.cardText}>
                  O SQUARE conecta bandas, profissionais e público através de um modelo de programação que agregará uma
                  convenção, um ciclo de showcases e propostas de clubbing, ligando quem faz a quem vê, quem cria a quem
                  programa, quem pensa a quem implementa e, com isso, sedimentando um ecossistema que possa gerar novas
                  ideias, novas oportunidades e novas concepções sobre as ideias de criatividade, sucesso e carreira. O
                  SQUARE acontece no âmbito do programa para a Braga25, Capital Portuguesa da Cultura e conta com o
                  apoio das cidades envolvidas.
                </p>
              </div>
              <button onClick={toggleExpand} className={classes.seeMoreButton}>
                <p className={classes.seeMoreText}>{expanded ? 'Ver menos' : 'Ver mais'}</p>
              </button>
            </div>
          </div>
          <div className={classes.image1}>
            <img src={image1} alt="logo" className={classes.image} loading="lazy" />
          </div>
          <div className={classes.image2}>
            <img src={image2} alt="logo" className={classes.image} loading="lazy" />
          </div>
        </section>

        <section className={`${classes.card} ${classes.bgLilas}`}>
          <p className={classes.cardText}>
            O festival procura artistas que trabalhem e vivam nos três continentes banhados pelo Atlântico. Serão
            aceites propostas em qualquer género musical, com qualquer tipo de formação em termos de número de pessoas e
            com música produzida em qualquer língua.
            <br />O Open Call estará aberto entre 16 de abril e 31 de julho. Os resultados serão anunciados em setembro
            de 2024.
          </p>

          <div className="col align-center mt-15 gap-2">
            <button
              className={buttonOpenCall}
              // onClick={() => {
              //   window.open('https://eu.jotform.com/form/240963903800051', '_blank');
              // }}
            >
              O Open Call está agora encerrado, obrigado!
            </button>

            <Link className={classes.textTerms} to="/pt/terms-and-conditions">
              Termos e Condições
            </Link>
          </div>

          <div className={classes.image3}>
            <img src={image3} alt="logo" className={classes.image} loading="lazy" />
          </div>
        </section>

        <section className={`${classes.card} ${classes.bgGreen}`}>
          <div className={classes.ratio}>
            <p className={classes.cardText}>
              Tendo como matriz a construção em rede, o programa do SQUARE será desenvolvido numa colaboração com
              diversos agentes, editoras, promotoras e festivais a trabalharem no espectro da música emergente nos três
              continentes. Os parceiros serão convidados a fazer curadoria na programação de artistas e da convenção. A
              nível regional estão já confirmadas as parcerias com a Bazuuca, a Cosmic Burger, a gig.rocks e a Revolve;
              a nível nacional com a Bantumen, o Festival de Músicas do Mundo, o MIL; e a a nível internacional com a
              Buh Records(PE), a Coquetel Molotov (BR), o COSMOS (NL), o Novas Frequências (BR), a Nyege Nyege (UG) e o
              Sinsal (ES).
            </p>
          </div>
          <div className={classes.image4}>
            <img src={image4} alt="logo" className={classes.image} loading="lazy" />
          </div>
          <div className={classes.image5}>
            <img src={image5} alt="logo" className={classes.image} loading="lazy" />
          </div>
        </section>
      </main>

      <Partners lang={'pt'} />
      <Footer lang={'pt'} />
    </div>
  );
};

export default HomePage;
