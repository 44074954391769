import React, { useState } from 'react';

import MailchimpForm from '../MailchimpForm';
import useStyles from './styles';


import logoVertical from 'assets/logo-vertical.svg';

const Footer = (props) => {
  const classes = useStyles();
  const [language] = useState(props.lang || 'en');


  return (
    <footer className={classes.footer}>

      <div className={classes.footerMaxWidth}>

        <div className={classes.footerCol}>
          <img src={logoVertical} alt="logo" className={classes.footerLogo} loading="lazy" />
        </div>

        <div className={classes.footerCol}>
          <MailchimpForm lang={language} />
        </div>
      </div>

      <div className={classes.footerMaxWidth}>

        <div className={classes.footerCol}>

          <div className={classes.footerTextWrap}>
            {language === "en" && (
              <p className={classes.footerTextLabel}>Contact</p>
            )}
            {language === "pt" && (
              <p className={classes.footerTextLabel}>Contacto</p>
            )}
            <a
              href="mailto:info@squarefestival.pt"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerLink}
            >
              info@squarefestival.pt
            </a>
          </div>
        </div>

        <div className={classes.footerCol}>
          <div className={classes.footerTextWrap}>
            <p className={classes.footerTextLabel}>Social</p>

            <div className='row gap-2'>
              <a
                href="https://www.facebook.com/profile.php?id=61558481751153"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerLink}
              >
                Facebook
              </a>

              <a
                href="https://www.instagram.com/squarefestival.pt/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerLink}
              >
                Instagram
              </a>
            </div>

          </div>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
