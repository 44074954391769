import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  footerMaxWidth: {
    width: "100%",
    maxWidth: "860px",
    display: "flex"
  },
  footerCol: {
    display: "flex",
    width: "50%"
  },
  footerTextWrap: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "1rem",
    paddingBottom: "2rem",
    gap: "1rem",
    flex: 1
  },
  footerLink: {
    fontSize: '1.1rem',
    textAlign: 'left',
    color: "var(--main-color)",
    '&:hover': {
      textDecoration: "underline"
    },
  },
  footerTextLabel: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '20px',
    textAlign: 'left',
    color: "white",
    letterSpacing: "0.4px",
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'var(--main-background-color)',
    color: 'white',
    padding: "2rem"
  },
  footerLogo: {
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
    padding: "50px 50px 50px 0"
  },
  '@media (max-width: 560px)': {
    footerMaxWidth: {
      flexDirection: 'column'
    },
    footerCol: {
      width: "100%",
      justifyContent: "center"
    },
  },
});


export default useStyles;
