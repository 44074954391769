import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import HomePage from 'pages/home';
import HomePT from 'pages/home-pt';
import Terms from 'pages/terms-and-conditions';
import TermsPT from 'pages/terms-and-conditions-pt';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/pt',
    element: <HomePT />
  },
  {
    path: '/terms-and-conditions',
    element: <Terms />
  },
  {
    path: '/pt/terms-and-conditions',
    element: <TermsPT />
  },
]);

const Router = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default Router;
