import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles';

import logo from 'assets/logo.svg';
import investidor from 'assets/partners/braga25/braga25.png';
import investidorPT from 'assets/partners/braga25/logo_braga25_CMYK_branco.png';

import pt from 'assets/pt.svg';
import en from 'assets/en.svg';

const Header = (props) => {
  const classes = useStyles();

  const [language] = useState(props.lang || 'en');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 560);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 560);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 560);
      setIsDesktop(window.innerWidth > 560);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={classes.App}>
      <div className={classes.hero}>
        {isDesktop && (
          <div className={classes.videoDesktop}>
            <iframe
              className={classes.video}
              src="https://player.vimeo.com/video/931483864?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&background=1&title=0&byline=0&portrait=0"
              frameBorder={0}
              title="Square-exp2"
            />
            <div className={classes.overlay} />
            <div className={classes.heroContent}>
              <div className={classes.heroTop}>
                {language === 'en' && (
                  <Link className={classes.investidor} to="https://braga25.pt/en/" target="_blank">
                    <img src={investidor} alt="braga25" />
                  </Link>
                )}
                {language === 'pt' && (
                  <Link className={classes.investidor} to="https://braga25.pt" target="_blank">
                    <img src={investidorPT} alt="braga25" />
                  </Link>
                )}
                {language === 'en' && (
                  <Link className={classes.heroLanguageChanger} to="/pt">
                    <img src={pt} alt="pt" />
                  </Link>
                )}
                {language === 'pt' && (
                  <Link className={classes.heroLanguageChanger} to="/">
                    <img src={en} alt="en" />
                  </Link>
                )}
              </div>
              <img src={logo} alt="logo" className={classes.logo} />
              <div className={classes.heroTextWrap}>
                <p className={classes.heroText}>29.01.2025 — 1.02.2025</p>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className={classes.videoMobile}>
            <iframe
              src="https://player.vimeo.com/video/931483894?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&background=1&title=0&byline=0&portrait=0"
              frameBorder={0}
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              className={classes.video}
              title="Vídeo Loop Square_Vertical"
            />
            <div className={classes.overlay} />
            <div className={classes.heroContent}>
              <div className={classes.heroTop}>
                {language === 'en' && (
                  <Link className={classes.investidor} to="https://braga25.pt/en/" target="_blank">
                    <img src={investidor} alt="braga25" />
                  </Link>
                )}
                {language === 'pt' && (
                  <Link className={classes.investidor} to="https://braga25.pt" target="_blank">
                    <img src={investidorPT} alt="braga25" />
                  </Link>
                )}
                {language === 'en' && (
                  <Link className={classes.heroLanguageChanger} to="/pt">
                    <img src={pt} alt="pt" />
                  </Link>
                )}
                {language === 'pt' && (
                  <Link className={classes.heroLanguageChanger} to="/">
                    <img src={en} alt="en" />
                  </Link>
                )}
              </div>
              <img src={logo} alt="logo" className={classes.logo} />
              <div className={classes.heroTextWrap}>
                <p className={classes.heroText}>29.01.2025 — 1.02.2025</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
