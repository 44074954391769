import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  hero: {
    maxHeight: '100vh',
    overflow: 'hidden',
    backgroundColor: 'var(--main-background-color)'
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: "500px",
    width: "80%",
    margin: "3rem 0",
    cursor: "pointer"
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#181818',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  main: {
    marginTop: 0,
  },
  '@media (max-width: 560px)': {
    videoMobile: {
      display: 'block',
    },
    videoDesktop: {
      display: 'none',
    },
  },



});


export default useStyles;
