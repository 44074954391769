import React, { useEffect, useState } from 'react';
import fetchData from '../AirtableService';
import useStyles from '../../pages/styles';
import { Link } from 'react-router-dom';

const Section2 = () => {
  const classes = useStyles();

  const [description, setDescription] = useState('');
  const [image1, setImage1] = useState('');
  const [image1Alt, setImage1Alt] = useState('');

  useEffect(() => {
    const getData = async () => {
      const records = await fetchData('Homepage');

      const filteredRecords = records.filter((record) => record.fields.Name === 'Square 2');

      if (filteredRecords.length > 0) {
        setDescription(filteredRecords[0].fields.Description);

        const assets = filteredRecords[0].fields.Assets || [];
        if (assets.length > 0) {
          const thumbnails = assets[0].thumbnails || {};
          setImage1(thumbnails.large.url);
          setImage1Alt(assets[0].filename);
        }
      }
    };

    getData();
  }, []);

  return (
    <section className={`${classes.card} ${classes.bgLilas}`}>
      <p className={classes.cardText}>{description}</p>
      <div className="col align-center mt-15 gap-2">
        <button
          className={classes.buttonOpenCall}
          // onClick={() => {
          //   window.open('https://eu.jotform.com/form/240963903800051', '_blank');
          // }}
        >
          Applications are now closed, thank you!
        </button>

        <Link className={classes.textTerms} to="/terms-and-conditions">
          Terms and conditions
        </Link>
      </div>
      <div className={classes.image3}>
        <img src={image1} alt={image1Alt} className={classes.image} loading="lazy" />
      </div>
    </section>
  );
};

export default Section2;
