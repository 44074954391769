import React, { useState, useEffect } from 'react';

function MailchimpForm(props) {
  const [language] = useState(props.lang || 'en');

  const submit = language === "pt" ? "Subscrever" : "Subscribe";

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      window.fnames = new Array();
      window.ftypes = new Array();
      fnames[0]='EMAIL';
      ftypes[0]='email';
      fnames[1]='FNAME';
      ftypes[1]='text';
      fnames[2]='LNAME';
      ftypes[2]='text';
      fnames[3]='ADDRESS';
      ftypes[3]='address';
      fnames[4]='PHONE';
      ftypes[4]='phone';
      fnames[5]='BIRTHDAY';
      ftypes[5]='birthday';
    `;
    document.body.appendChild(script);

    const jqueryScript = document.createElement('script');
    jqueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    jqueryScript.onload = () => {
      const mcScript = document.createElement('script');
      mcScript.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
      document.body.appendChild(mcScript);
    };
    document.body.appendChild(jqueryScript);



    // Append custom styles
    const customStyles = document.createElement('style');
    customStyles.innerHTML = `
    /* Add your custom styles here */
    /* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */

    #mc_embed_shell {
      width: 100%;
    }

    #mc_embed_signup form {
        display: block;
        position: relative;
        text-align: left;
    }

    #mc_embed_signup h2 {
        font-weight: bold;
        padding: 0;
        margin: 15px 0;
        font-size: 1.4em;
    }

    #mc_embed_signup input {
        border: 1px solid #ABB0B2;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }

    #mc_embed_signup input[type=checkbox] {
        -webkit-appearance: checkbox;
    }

    #mc_embed_signup input[type=radio] {
        -webkit-appearance: radio;
    }

    #mc_embed_signup .button {
        clear: both;
        border: 0 none;
        border-radius: 4px;
        transition: all 0.23s ease-in-out 0s;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        font-size: 1.1rem;
        font-weight: normal;
        line-height: 32px;
        margin: 10px 0;
        padding: 8px 16px;
        text-align: center;
        text-decoration: none;
        vertical-align: top;
        white-space: nowrap;
        background: var(--main-color);
        color: var(--main-background-color);
        max-width: 200px;
        font-family: Switzer;
        font-weight: 600;
        border-radius: 2rem;
    }

    #mc_embed_signup .button:hover {
        background-color: var(--secondary-color);
    }

    #mc_embed_signup .small-meta {
        font-size: 11px;
    }

    #mc_embed_signup .nowrap {
        white-space: nowrap;
    }

    #mc_embed_signup .mc-field-group {
        clear: left;
        position: relative;
        width: 96%;
        padding-bottom: 3%;
        min-height: 50px;
        display: grid;
    }

    #mc_embed_signup .size1of2 {
        clear: none;
        float: left;
        display: inline-block;
        width: 46%;
        margin-right: 4%;
    }

    * html #mc_embed_signup .size1of2 {
        margin-right: 2%;
        /* Fix for IE6 double margins. */
    }

    #mc_embed_signup .mc-field-group label {
        display: block;
        color: white;
    }

    #mc_embed_signup .mc-field-group input {
        display: block;
        width: 100%;
        padding: 8px 0;
        text-indent: 2%;
        border: none;
        background: none;
        border-bottom: 3px solid var(--main-color);
        color: var(--main-color);
    }

    #mc_embed_signup .mc-field-group input:focus {
      outline: none;
      border-bottom: 3px solid var(--secondary-color);
    }

    #mc_embed_signup .mc-field-group select {
        display: inline-block;
        width: 99%;
        padding: 5px 0;
        margin-bottom: 2px;
    }

    #mc_embed_signup .mc-address-fields-group {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 96%;
        gap: 15px;
    }

    #mc_embed_signup .mc-sms-phone-group {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 96%;
        gap: 15px;
        padding-top: 5px;
    }

    #mc_embed_signup .datefield, #mc_embed_signup .phonefield-us {
        padding: 5px 0;
    }

    #mc_embed_signup .datefield input, #mc_embed_signup .phonefield-us input {
        display: inline;
        width: 60px;
        margin: 0 2px;
        letter-spacing: 1px;
        text-align: center;
        padding: 5px 0 2px 0;
    }

    #mc_embed_signup .phonefield-us .phonearea input, #mc_embed_signup .phonefield-us .phonedetail1 input {
        width: 40px;
    }

    #mc_embed_signup .datefield .monthfield input, #mc_embed_signup .datefield .dayfield input {
        width: 30px;
    }

    #mc_embed_signup .datefield label, #mc_embed_signup .phonefield-us label {
        display: none;
    }

    #mc_embed_signup .indicates-required {
        text-align: right;
        font-size: 11px;
        margin-right: 4%;
    }

    #mc_embed_signup .asterisk {
        color: #e85c41;
        font-size: 150%;
        font-weight: normal;
        position: relative;
        top: 5px;
    }

    #mc_embed_signup .clear {
        clear: both;
    }

    #mc_embed_signup .foot {
        display: grid;
        grid-template-columns: 3fr 1fr;
        width: 96%;
        align-items: center;
    }

    @media screen and (max-width: 400px) {
        #mc_embed_signup .foot {
            display:grid;
            grid-template-columns: 1fr;
            width: 100%;
            align-items: center;
        }
    }

    @media screen and (max-width: 400px) {
        #mc_embed_signup .referralBadge {
            width:50%;
        }
    }

    #mc_embed_signup .brandingLogo {
        justify-self: right;
    }

    @media screen and (max-width: 400px) {
        #mc_embed_signup .brandingLogo {
            justify-self:left;
        }
    }

    #mc_embed_signup .mc-field-group.input-group ul {
        margin: 0;
        padding: 5px 0;
        list-style: none;
    }

    #mc_embed_signup .mc-field-group.input-group ul li {
        display: block;
        padding: 3px 0;
        margin: 0;
    }

    #mc_embed_signup .mc-field-group.input-group label {
        display: inline;
    }

    #mc_embed_signup .mc-field-group.input-group input {
        display: inline;
        width: auto;
        border: none;
    }

    #mc_embed_signup div#mce-responses {
        position: relative;
        top: -1.4em;
        overflow: hidden;
        width: 90%;
        clear: both;
        color: var(--main-color);
    }

    #mc_embed_signup div.response {
        margin: 1em 0;
        padding: 1em .5em .5em 0;
        font-weight: bold;
        float: left;
        top: -1.5em;
        z-index: 1;
        width: 80%;
    }

    #mc_embed_signup #mce-error-response {
        display: none;
    }

    #mc_embed_signup #mce-success-response {
        color: #529214;
        display: none;
    }

    #mc_embed_signup label.error {
        display: block;
        float: none;
        width: auto;
        margin-left: 1.05em;
        text-align: left;
        padding: .5em 0;
    }

    #mc_embed_signup .helper_text {
        color: #8d8985;
        margin-top: 2px;
        display: inline-block;
        padding: 3px;
        background-color: rgba(255,255,255,0.85);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: normal;
        z-index: 1;
    }

    #mc_embed_signup #num-subscribers {
        font-size: 1.1em;
    }

    #mc_embed_signup #num-subscribers span {
        padding: .5em;
        border: 1px solid #ccc;
        margin-right: .5em;
        font-weight: bold;
    }

    #mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
        display: inline-block;
        padding-top: 5px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: normal;
        z-index: 1;
        color: #e85c41;
        background: none;
        display: none !important;
    }

    #mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
      border: none;
      border-bottom: 3px solid #e85c41;
    }

    #mc_embed_signup {
      padding: 50px 0;
    }
    #mc_embed_signup h2 {
      font-family: ABCDiatypeRoundedUnlicensedTrial;
    }
  `;
    document.head.appendChild(customStyles);


    return () => {
      document.body.removeChild(script);
      document.body.removeChild(jqueryScript);
    };
  }, []);

  return (
    <div id="mc_embed_shell">
      <div id="mc_embed_signup">
        <form action="https://squarefestival.us18.list-manage.com/subscribe/post?u=63c990832b730e6c533b53c9d&amp;id=4ddb580f37&amp;f_id=00f9b9e7f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
          <div id="mc_embed_signup_scroll">
            <h2>Newsletter</h2>

            {language === "en" && (
              <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
            )}
            {language === "pt" && (
              <div className="indicates-required"><span className="asterisk">*</span> campo obrigatório</div>
            )}

            <div className="mc-field-group">
              {language === "en" && (
                <label htmlFor="mce-EMAIL">Email <span className="asterisk">*</span></label>
              )}
              {language === "pt" && (
                <label htmlFor="mce-EMAIL">Email <span className="asterisk">*</span></label>
              )}

              <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" />
            </div>
            <div className="mc-field-group">
              {language === "en" && (
                <label htmlFor="mce-FNAME">First Name <span className="asterisk">*</span></label>
              )}

              {language === "pt" && (
                <label htmlFor="mce-FNAME">Primeiro nome <span className="asterisk">*</span></label>
              )}
              <input type="text" name="FNAME" className="required text" id="mce-FNAME" required="" />
            </div>
            <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input type="text" name="b_63c990832b730e6c533b53c9d_4ddb580f37" tabIndex="-1" defaultValue="" />
            </div>
            <div className="optionalParent">
              <div className="clear foot">
                  <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value={submit} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MailchimpForm;
