import React, { useEffect } from 'react';
import useStyles from '../styles';

import Hero from 'components/hero';
import Footer from 'components/footer';
import { useLocation } from "react-router-dom";


const HomePage = () => {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = useStyles();

  return (
    <div className={classes.App}>
      <Hero lang={"pt"} isSingle />

      <main className={`${classes.main} isSingle`}>
        <section className={`${classes.card} ${classes.bgGreen} isSingle`}>
          <div className={classes.ratio}>
            <div className={classes.cardText}>
              <p dir="ltr">
                SQUARE 2025 Open Call is open to artists based in any country of Europe,
                Africa, and the Americas.<br/><br/>
              </p>
              <p dir="ltr">In case of selection,&nbsp; SQUARE 2025 will provide:<br/><br/></p>
              <ul>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    €750 contribution per performance (payments will require an invoice);
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Dinner for the presentation night, for each on-stage artist + sound
                    professional + artist representative (manager, agent, etc.);
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Standard sound and lighting equipment + shared backline (bass and guitar
                    amplifiers, standard drum kit, DJ equipment);
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Technical assistance before, during, and after the show (sound, light,
                    and stage);
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Artist promotion: SQUARE will communicate about artists/bands through
                    its communication tools;
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Contact with journalists and media outlets;
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Two PRO credentials for artist/band representatives, providing full
                    access to the convention and festival program;
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Tools for the entity representing artists/bands to contact Square
                    participants;
                  </p>
                </li>
              </ul>
              <p dir="ltr">
                Extra costs, left to the entity representing artists/bands, if necessary:
              </p>
              <ul>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Specific technical requests and additional backline;
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    It is agreed and understood that artists/bands will have to share the
                    stage and backstage with other artists/bands also integrated into the
                    festival. Artists that play different musical genres and come from
                    different countries. Following this, it is also agreed upon the
                    possibility of sharing backline.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div >
  );
};

export default HomePage;
