import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import logo from 'assets/logo.svg';

const Header = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.App}>
      <div className={classes.hero}>
        <div className={classes.videoDesktop}>
          <div className={classes.heroContent}>
            <div className={classes.logo} onClick={() => navigate(-1)}>
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Header;
