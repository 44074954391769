import React, { useEffect, useState } from 'react';
import fetchData from '../AirtableService';

import useStyles from '../../pages/styles';

const Section1 = () => {
  const classes = useStyles();

  const [description, setDescription] = useState('');
  const [description2, setDescription2] = useState('');
  const [image1, setImage1] = useState('');
  const [image1Alt, setImage1Alt] = useState('');
  const [image2, setImage2] = useState('');
  const [image2Alt, setImage2Alt] = useState('');
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const records = await fetchData('Homepage');

      const filteredRecords = records.filter((record) => record.fields.Name === 'Square 1');

      if (filteredRecords.length > 0) {
        setDescription(filteredRecords[0].fields.Description);
        setDescription2(filteredRecords[0].fields['Description 2']);

        const assets = filteredRecords[0].fields.Assets || [];
        if (assets.length > 0) {
          const thumbnails = assets[0].thumbnails || {};
          setImage1(thumbnails.large.url);
          setImage1Alt(assets[0].filename);
          if (assets.length > 1) {
            setImage2(assets[1].thumbnails.large.url);
            setImage2Alt(assets[1].filename);
          }
        }
      }
    };

    getData();
  }, []);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <section className={`${classes.card} ${classes.bgGreen}`}>
      <div className={classes.ratio}>
        <p className={classes.cardText}>{description}</p>

        <div className={classes.seeMoreWrapper}>
          <div className={`${classes.seeMoreHidden} ${expanded ? classes.seeMoreVisible : ''}`}>
            <p className={classes.cardText}>{description2}</p>
          </div>
          <button onClick={toggleExpand} className={classes.seeMoreButton}>
            <p className={classes.seeMoreText}>{expanded ? 'View less' : 'View more'}</p>
          </button>
        </div>
      </div>
      <div className={classes.image1}>
        <img src={image1} alt={image1Alt} className={classes.image} loading="lazy" />
      </div>
      <div className={classes.image2}>
        <img src={image2} alt={image2Alt} className={classes.image} loading="lazy" />
      </div>
    </section>
  );
};

export default Section1;
