import React, { useEffect, useState } from 'react';
import fetchData from '../AirtableService';
import { Link } from 'react-router-dom';
import useStyles from './styles';

import organizer from 'assets/partners/organização/logo_L&L.png';


const Partners = (props) => {
  const [image1, setImage1] = useState('');
  const [image1Alt, setImage1Alt] = useState('');

  const [promoters, setPromoters] = useState([]);
  const [hosting, setHostingPartners] = useState([]);
  const [creative, setCreativePartners] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const records = await fetchData('Partners');

      const mainRecords = records.filter((record) => record.fields.Name === 'Main');
      const promoterRecords = records.filter((record) => record.fields.Name === 'Promoters');
      const hostingRecords = records.filter((record) => record.fields.Name === 'Hosting Partners');
      const creativeRecords = records.filter((record) => record.fields.Name === 'Creative Partners');

      const promotersArray = [];
      const hostingArray = [];
      const creativeArray = [];

      if (mainRecords.length > 0) {

        const mainAssets = mainRecords[0].fields.Images || [];
        if (mainAssets.length > 0) {
          const mainThumbnails = mainAssets[0].thumbnails || {};
          setImage1(mainThumbnails.large.url);
          setImage1Alt(mainAssets[0].filename);
        }
      }

      if (promoterRecords.length > 0) {

        promoterRecords.forEach((record) => {
          const promoterAssets = record.fields.Images || [];
          promoterAssets.forEach((asset) => {
            const thumbnails = asset.thumbnails || {};
            promotersArray.push({
              url: thumbnails.large.url,
              alt: asset.filename,
            });
          });
        });
      }

      if (hostingRecords.length > 0) {

        hostingRecords.forEach((record) => {
          const hostingAssets = record.fields.Images || [];
          hostingAssets.forEach((asset) => {
            const thumbnails = asset.thumbnails || {};
            hostingArray.push({
              url: thumbnails.large.url,
              alt: asset.filename,
            });
          });
        });
      }

      if (creativeRecords.length > 0) {

        creativeRecords.forEach((record) => {
          const creativeAssets = record.fields.Images || [];

          creativeAssets.forEach((asset) => {
            const thumbnails = asset.thumbnails || {};
            creativeArray.push({
              url: thumbnails.large.url,
              alt: asset.filename,
            });
          });
        });
      }

      setPromoters(promotersArray);
      setHostingPartners(hostingArray);
      setCreativePartners(creativeArray);
    };

    getData();
  }, []);


  const classes = useStyles();

  const [language] = useState(props.lang || 'en');



  return (
    <div className={classes.partnerSection}>
      {language === 'en' && <p className={classes.partnersTitle}>Partners</p>}

      {language === 'pt' && <p className={classes.partnersTitle}>Parceiros</p>}

      <div className={classes.partnersMaxWidth}>
        <div className={classes.partnerList}>
          <div className={classes.partner}>
            {language === 'en' && (
              <Link to="https://braga25.pt/en/" target="_blank">
                <img className="big" src={image1} alt={image1Alt} loading="lazy" />
              </Link>
            )}

            {language === 'pt' && (
              <Link to="https://braga25.pt/" target="_blank">
                <img className="big" src={image1} alt={image1Alt} loading="lazy" />
              </Link>
            )}
          </div>
        </div>

        {language === 'en' && <p className={classes.partnerTitle}>Promoters</p>}

        {language === 'pt' && <p className={classes.partnerTitle}>Promotores</p>}

        <div className={classes.partnerList}>
          {promoters.map((parceiro, index) => (
            <div className={classes.partner} key={index}>
              <img src={parceiro.url} alt={parceiro.alt} loading="lazy" />
            </div>
          ))}
        </div>

        {language === 'en' && <p className={classes.partnerTitle}>Organization</p>}

        {language === 'pt' && <p className={classes.partnerTitle}>Organização</p>}

        <div className={classes.partnerList}>
          <div className={classes.partner}>
            <img src={organizer} alt="" loading="lazy" />
          </div>
        </div>

        {language === 'en' && <p className={classes.partnerTitle}>Hosting Partners</p>}

        {language === 'pt' && <p className={classes.partnerTitle}>Parceiros de Acolhimento</p>}

        <div className={classes.partnerList}>
          {hosting.map((item, index) => (
            <div className={classes.partner} key={index}>
              <img src={item.url} alt={item.alt} loading="lazy" />
            </div>
          ))}
        </div>

        {language === 'en' && <p className={classes.partnerTitle}>Creative Partners</p>}

        {language === 'pt' && <p className={classes.partnerTitle}>Parceiros Criativos</p>}

        <div className={classes.partnerList}>
          {creative.map((item, index) => (
            <div className={classes.partner} key={index}>
              <img src={item.url} alt={item.alt} loading="lazy" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
