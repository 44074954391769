import React, { useEffect, useState } from 'react';
import fetchData from '../AirtableService';
import useStyles from '../../pages/styles';

const Section3 = () => {
  const classes = useStyles();

  const [description, setDescription] = useState('');
  const [image1, setImage1] = useState('');
  const [image1Alt, setImage1Alt] = useState('');
  const [image2, setImage2] = useState('');
  const [image2Alt, setImage2Alt] = useState('');

  useEffect(() => {
    const getData = async () => {
      const records = await fetchData('Homepage');

      const filteredRecords = records.filter((record) => record.fields.Name === 'Square 3');

      if (filteredRecords.length > 0) {

        setDescription(filteredRecords[0].fields.Description);

        const assets = filteredRecords[0].fields.Assets || [];
        if (assets.length > 0) {
          const thumbnails = assets[0].thumbnails || {};
          setImage1(thumbnails.large.url);
          setImage1Alt(assets[0].filename);
          if (assets.length > 1) {
            setImage2(assets[1].thumbnails.large.url);
            setImage2Alt(assets[1].filename);
          }
        }
      }
    };

    getData();
  }, []);

  return (
    <section className={`${classes.card} ${classes.bgGreen}`}>
      <div className={classes.ratio}>
        <p className={classes.cardText}>
          {description}
        </p>
      </div>
      <div className={classes.image4}>
        <img src={image1} alt={image1Alt} className={classes.image} loading="lazy" />
      </div>
      <div className={classes.image5}>
        <img src={image2} alt={image2Alt} className={classes.image} loading="lazy" />
      </div>
    </section>
  );
};

export default Section3;
